import * as React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import Burst from '../components/burst';

const beniget = () => {
	return(
		<ModalRoutingContext.Consumer>
		{({ modal, closeTo }) => (
			<>
			  <Link 
			  	className="Modal-close beignet"
			  	to={closeTo}
			  	state={{noScroll: true }}
			  >
				<span></span>
			  </Link>
			<div className="Work-wrap beignet-modal">
				<img className="tree tree1 no-touch" src={'work/beignet/tree1.svg'} />
				<img className="tree tree2 no-touch" src={'work/beignet/tree2.svg'} />
				<div className="cloud cloud1 no-touch"></div>
				<div className="cloud cloud2 no-touch"></div>
				<div className="cloud cloud3 no-touch"></div>
				<ul className="Work-slides">
					<li className="spacer"></li>
					<li className="Title">
						<h3 className="font-title text-center">Beignet Fest</h3>
					</li>
					<li className="Work">
						<div className="">
							<Burst/>
						</div>
					</li>
					<li className="Work">
						<div>
							<picture>
								<source srcset={'/work/beignet/1/1-a.png'} media="(min-width: 59em)" />
								<source srcset={'/work/beignet/1/1-b.png'} media="(min-width: 34em)" />
								<source srcset={'/work/beignet/1/1-c.jpg'} media="(max-width: 34em)" />
								<img src={"/work/beignet/1/1-a.png"} alt="Beignet Fest Work Example" />
							</picture>
						</div>
					</li>
					<li className="Work">
						<div className="beinget-goto goto">
							<p>Beignet Fest celebrates New Orleans’s most iconic dessert: the beignet. From traditional sweet treats to savory delicacies that feature seafood, cheese and more, Beignet Fest serves up the best beignets in the South. Proceeds from the festival benefit the Tres Doux Foundation</p>
							<a target="blank" rel="noreferrer" href="https://beignetfest.com/"><p>View Site</p></a>
						</div>
					</li>
				</ul>
		  </div>
		  </>
		)}
	  </ModalRoutingContext.Consumer>
	)
}

export default beniget